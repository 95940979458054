import { createApp } from 'vue';
import App from './App.vue';

import VueGtag from 'vue-gtag';

import './registerServiceWorker';
import router from '@/router';
import store from './store';

import './main.css';

createApp(App).use(store).use(router).use(VueGtag, {
  config: { id: 'G-BKD82R9EES' }
}).mount('#app');
