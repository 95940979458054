<template>
  <router-view
    :key="$route.fullPath"
    @wheel="(ev: WheelEvent) => debounce(100, wheeler, ev, router)"
    @touchstart="(ev: TouchEvent) => posTouchStart = { x: ev.touches[0].clientX, y: ev.touches[0].clientY }"
    @touchend="(ev: TouchEvent) => touchEnd(ev, router)"
  />
</template>

<style lang="scss">
img {
  -webkit-user-drag: none;
  user-select: none;
}
</style>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

import { debounce, wheeler, posTouchStart, touchEnd, keyupFn } from '@/share';

const router = useRouter();

onMounted(() => {
  document.addEventListener('keyup', (ev: KeyboardEvent) => keyupFn(ev, router));
});

onBeforeUnmount(() => {
  document.removeEventListener('keyup', (ev: KeyboardEvent) => keyupFn(ev, router));
});
</script>
