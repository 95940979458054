import { ref } from 'vue';
import { Router } from 'vue-router';

import portfolios from '@/assets/portfolios.json';
import pieces from '@/assets/pieces.json';
import products from '@/assets/products.json';

export const dataLength = {
  portfolios: portfolios.length,
  pieces: pieces.length,
  products: products.length
};

export interface TargetType {
  _id: string;
  img: {
    path: string;
    width: number;
    height: number;
    desktop: {
      mainWidth: string;
      mainHeight: string;
      miniWidth: string;
      miniHeight: string;
    };
    mobile: {
      mainWidth: string;
      mainHeight: string;
      miniWidth: string;
      miniHeight: string;
    };
  }[];
  price?: number;
  size?: string;
  material?: string[];
  light?: string;
  note?: string;
  desktop?: {
    translateX: string;
    translateY: string;
    translateMainX: string;
    translateMainY: string;
    translateMiniX: string;
    translateMiniY: string;
  };
  mobile?: {
    translateX: string;
    translateY: string;
    translateMainX: string;
    translateMainY: string;
    translateMiniX: string;
    translateMiniY: string;
  }
};

const switcherIdx = 1;

let timeoutID: ReturnType<typeof setTimeout> | null = null;

export const debounce = (delay: number, fn: (ev: KeyboardEvent | WheelEvent | MouseEvent, router: Router) => void, ev: KeyboardEvent | WheelEvent | MouseEvent, router:Router):void => {
  if (timeoutID) {
    clearTimeout(timeoutID);
  };

  timeoutID = setTimeout(() => {
    fn(ev, router);
  }, delay);
};

export const pageNext = (router: Router) => {
  const unitId = String(router.currentRoute.value.name).toLowerCase();

  console.log(unitId);

  if (unitId === 'dev portfolio') return;

  if (unitId === 'intro' || unitId === 'portfolio') {
    const pageNum = Number(router.currentRoute.value.params[unitId + 'ID']);

    return (unitId === 'intro')
      ? (pageNum < 6) && router.push(`/${unitId}/${pageNum + 1}`)
      : (pageNum < dataLength.portfolios - 1) && router.push(`/${unitId}/${pageNum + 1}`);
  } else {
    const pageId = String(router.currentRoute.value.params[unitId + 'ID']).split('_');
    const pageYear = pageId[0];
    const pageNum = Number(pageId[1]);

    return pageNum + 1 > dataLength[unitId === 'piece' ? 'pieces' : 'products']
      ? null
      : router.push(`/${unitId}/${pageYear}_${String(pageNum + 1).padStart(2, '0')}`);
  }
};

export const pagePrev = (router: Router) => {
  const unitId = String(router.currentRoute.value.name).toLowerCase();
  const pageId = String(router.currentRoute.value.params[unitId + 'ID']).split('_');
  const pageYear = pageId[0];
  const pageNum = Number(pageId[pageId.length - 1]);

  switch (true) {
    case unitId === 'intro':
    case unitId === 'portfolio':
      return (pageNum > 0)
        ? router.push(`/${unitId}/${pageNum - 1}`)
        : (unitId === 'portfolio')
            ? router.push('/intro/6')
            : null;

    case unitId === 'piece':
    case unitId === 'product':
      return (pageNum > 1)
        ? router.push(`/${unitId}/${pageYear}_${String(pageNum - 1).padStart(2, '0')}`)
        : router.push('/intro/6');

    case unitId === 'dev portfolio':
      return router.push('/intro/6');
  }
};

export const wheeler = (ev: KeyboardEvent | WheelEvent | Router, router: Router):void => {
  if (!(ev instanceof WheelEvent) || window.innerWidth < 1024) return;
  const delY = ev.deltaY;
  if (delY > switcherIdx) pageNext(router);
  else if (delY < switcherIdx * (-1)) pagePrev(router);
};

export const posTouchStart = ref({ x: 0, y: 0 });

const posTouchEnd = ref({ x: 0, y: 0 });

export const touchEnd = (ev: TouchEvent, router: Router):void => {
  posTouchEnd.value = {
    x: ev.changedTouches[0].clientX,
    y: ev.changedTouches[0].clientY
  };

  const delPos = {
    x: posTouchEnd.value.x - posTouchStart.value.x,
    y: posTouchEnd.value.y - posTouchStart.value.y
  };

  if (delPos.y > 30 || delPos.y < -30) return;
  if (delPos.x < -30) pageNext(router);
  if (delPos.x > 30) pagePrev(router);
};

export const keyupFn = (ev: KeyboardEvent, router: Router):void => {
  const keyer = (ev: KeyboardEvent | WheelEvent | MouseEvent) => {
    if (!(ev instanceof KeyboardEvent)) return;
    switch (ev.key) {
      case 'ArrowUp':
        pagePrev(router);
        break;
      case 'ArrowDown':
        pageNext(router);
        break;
      case 'ArrowLeft':
        pagePrev(router);
        break;
      case 'ArrowRight':
        pageNext(router);
        break;
    };
  };

  debounce(100, keyer, ev, router);
};
